<div class="mx-auto px-4 lg:px-12">
  <div class="bg-white relative shadow-md sm:rounded-lg overflow-hidden">
    <div class="bg-white p-4 rounded-t-lg">
      <!-- Header -->
      <app-table-header></app-table-header>
      <div class="">
        <!-- Filters -->
        <app-filters-for-expandable
          [data]="data"
          [bankList]="bankList"
          [stepsList]="stepsList"
          [statusList]="statusList"
          [orderList]="orderList"
          [transmitterList]="transmitterList"
          [receiverList]="receiverList"
          [selectedRange]="selectedRange"
          [dateSetting]="dateSetting"
          [page]="page"
          [totalPages]="totalPages"
          [totalRows]="totalRows"
          [limit]="limit"
        ></app-filters-for-expandable>
      </div>

      <div (click)="closeFilters()" class="xl:mt-[-2rem]">
        <div>
          <p
            class="font-bold"
            *ngIf="
              _user.selectedResponsable ||
              _user.selectedStatus ||
              _user.bank_filter ||
              _user.sort_filter ||
              _user.emisor_filter ||
              _user.receptor_filter ||
              _user.selectedOrden ||
              _user.selectedSignature
            "
          >
            Filtros aplicados:
          </p>
          <div class="flex flex-col lg:flex-row gap-2 lg:h-9 lg:items-center mb-4">
            <span
              *ngIf="_user.selectedResponsable"
              class="bg-blue-100 text-blue-400 text-base font-medium px-2.5 py-0.5 rounded"
            >
              <span class="font-bold cursor-pointer" (click)="cleanFilter(1)">
                X &nbsp;</span
              >
              {{
                findLabel(_user.selectedResponsable, "selectedResponsable")
              }}</span
            >
            <span
              *ngIf="_user.selectedStatus"
              class="bg-blue-100 text-blue-400 text-base font-medium px-2.5 py-0.5 rounded"
              ><span class="font-bold cursor-pointer" (click)="cleanFilter(2)">
                X &nbsp;</span
              >
              {{ findLabel(_user.selectedStatus, "selectedStatus") }}</span
            >
            <span
              *ngIf="_user.bank_filter"
              class="bg-blue-100 text-blue-400 text-base font-medium px-2.5 py-0.5 rounded"
            >
              <span class="font-bold cursor-pointer" (click)="cleanFilter(3)">
                X &nbsp;</span
              >
              {{ findLabel(_user.bank_filter, "bank_filter") }}</span
            >
            <span
              *ngIf="_user.sort_filter"
              class="bg-blue-100 text-blue-400 text-base font-medium px-2.5 py-0.5 rounded"
            >
              <span class="font-bold cursor-pointer" (click)="cleanFilter(4)">
                X &nbsp;</span
              >
              {{ findLabel(_user.sort_filter, "sort_filter") }}</span
            >
            <span
              *ngIf="_user.emisor_filter"
              class="bg-blue-100 text-blue-400 text-base font-medium px-2.5 py-0.5 rounded"
              ><span class="font-bold cursor-pointer" (click)="cleanFilter(5)">
                X &nbsp;</span
              >
              {{ findLabel(_user.emisor_filter, "emisor_filter") }}</span
            >
            <span
              *ngIf="_user.receptor_filter"
              class="bg-blue-100 text-blue-400 text-base font-medium px-2.5 py-0.5 rounded"
              ><span class="font-bold cursor-pointer" (click)="cleanFilter(6)">
                X &nbsp;</span
              >
              {{ findLabel(_user.receptor_filter, "receptor_filter") }}</span
            >
            <span
              *ngIf="_user.selectedOrden"
              class="bg-blue-100 text-blue-400 text-base font-medium px-2.5 py-0.5 rounded"
              ><span class="font-bold cursor-pointer" (click)="cleanFilter(7)">
                X &nbsp;</span
              >
              {{ findLabel(_user.selectedOrden, "selectedOrden") }}</span
            >


            <span
            *ngIf="_user.selectedSignature"
            class="bg-blue-100 text-blue-400 text-base font-medium px-2.5 py-0.5 rounded"
            ><span class="font-bold cursor-pointer" (click)="cleanFilter(8)">
              X &nbsp;</span
            >
            {{ findLabel(_user.selectedSignature, "selectedSignature")  }}</span
          >
          
          </div>
        </div>
      </div>
    </div>

    <div class="border-b mx-4" [ngClass]="{' mt-[-70px]': !validateSomeFilter()}">
      <div class="overflow-x-auto">
        <table class="w-full text-sm text-left text-gray-500 mt-4">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" class="p-4">
                <div class="flex items-center">
                  <input
                    id="checkbox-all"
                    type="checkbox"
                    class="w-4 h-4 text-primary-600 bg-gray-100 rounded border-gray-300 focus:ring-primary-500"
                  />
                  <label for="checkbox-all" class="sr-only">checkbox</label>
                </div>
              </th>
              <th scope="col" class="px-4 py-3">Orden</th>
              <th scope="col" class="px-4 py-3">Emisor / Receptor</th>
              <th scope="col" class="px-4 py-3">Montos</th>
              <th scope="col" class="px-4 py-3">Status</th>
              <th scope="col" class="px-4 py-3">Responsables</th>
              <th scope="col" class="px-4 py-3">Fecha Creación</th>
              <th scope="col" class="px-4 py-3">Fecha Emisión</th>
              <th scope="col" class="px-4 py-3">Fecha Vencimiento</th>

              <!-- <th scope="col" class="px-4 py-3">Time Tracking</th> -->
              <th scope="col" class="px-4 py-3">Vencimiento</th>
              <th scope="col" class="px-4 py-3">Prioridad</th>
              <th scope="col" class="px-4 py-3">Banco destino</th>
              <th scope="col" class="px-4 py-3 min-w-[14rem]">Progreso</th>
            </tr>
          </thead>
          <tbody *ngIf="data.length > 0 && validateLoading()">
            <tr
              class="border-b hover:bg-gray-100"
              *ngFor="let item of data"
              (click)="toggleExpand(item)"
            >
              <td class="px-4 py-2 w-4">
                <div class="flex items-center">
                  <input
                    id="checkbox-table-search-1"
                    type="checkbox"
                    onclick="event.stopPropagation()"
                    class="w-4 h-4 text-primary-600 bg-gray-100 rounded border-gray-300 focus:ring-primary-500"
                  />
                  <label for="checkbox-table-search-1" class="sr-only"
                    >checkbox</label
                  >
                </div>
              </td>

              <td class="px-4 py-2 whitespace-nowrap">
                <div class="flex justify-center mb-1 items-center gap-1">
                  <span
                    class="text-xs font-bold text-indigo-500"
                    (click)="geItemInfo(item)"
                    >{{ validateInfo(item.orden.order) }}</span
                  >
                </div>
              </td>

              <th
                scope="row"
                class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap max-w-96"
              >
                <div>

                  <div
                  class="mt-2"

                    *ngIf="item.proveedor.name"

                  >
                    <span class="small">Emisor: </span>
                    <span
                      class="text-blue-400 cursor-pointer ml-3"
                      *ngIf="item.organizacion.name.toString().length < 24"
                      (click)="goToOrganization(item)"
                      >{{ validateInfo(item.organizacion.name) }}</span
                    >
                    <span
                      class="text-blue-400 cursor-pointer ml-3"
                      *ngIf="item.organizacion.name.toString().length >= 24"
                      (click)="goToOrganization(item)"
                      >{{
                        validateInfo(
                          item.organizacion.name.toString().substring(0, 24) +
                            "..."
                        )
                      }}</span
                    >
                  </div>


                  <div class="" *ngIf="item.organizacion.name">
                    <span class="small">Receptor: </span>
                    <span
                      class="text-blue-400 cursor-pointer"
                      *ngIf="item.proveedor.name.toString().length < 24"
                      (click)="gotoProvider(item)"
                    >
                      {{ validateInfo(item.proveedor.name) }}
                    </span>
                    <span
                      (click)="gotoProvider(item)"
                      *ngIf="item.proveedor.name.toString().length >= 24"
                      class="text-blue-400 cursor-pointer"
                    >
                      {{
                        validateInfo(item.proveedor.name)
                          .toString()
                          .substring(0, 24) + "..."
                      }}
                    </span>

                    <!-- <span class="ml-2 text-yellow-500 font-semibold">10</span> -->
                  </div>



                  <!-- <div class="text-gray-500 text-sm">
                    Factura numero:
                    {{ validateInfo(item.vencimiento.s._id) }}
                  </div> -->



                  <!-- <div class="text-gray-500 text-sm">
                    Created: {{ validateInfo(item.creacion) }}
                  </div> -->
                </div>
              </th>
              <td class="px-4 py-2 font-medium whitespace-nowrap">
                <div class="flex justify-end mb-1 items-center gap-1">
                  <span> {{ validateInfo(item.monto) | currency }} </span>
                  <span class="text-xs font-medium text-gray-500">{{
                    validateInfo(item.moneda)
                  }}</span>
                </div>
              </td>

              <td class="px-4 py-2 whitespace-nowrap">
                <div [class]="getProgressClass(item.estatus)">
                  {{
                    validateInfo(
                      item.estatus.toString().trim().toLowerCase() == "borrador"
                        ? "Anulado"
                        : item.estatus
                    )
                  }}
                </div>
              </td>

              <td class="px-4 py-2 whitespace-nowrap">
                <div class="flex justify-center items-center">
                  <i
                    class="fas fa-lock text-danger text-xl"
                    *ngIf="item['-']"
                  ></i>
                  <i
                    class="fas fa-unlock text-success text-xl"
                    *ngIf="!item['-']"
                  ></i>
                  <!-- <a
                    href="#"
                    class="flex-shrink-0 flex items-center justify-center w-10 h-10 text-xs font-medium text-white bg-gray-900 border-2 border-white rounded-full hover:bg-gray-600"
                    >+3</a
                  >
                  <img
                    src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/avatar-10.png"
                    alt=""
                    class="w-10 h-10 flex-shrink-0 border-2 border-white rounded-full"
                  /> -->
                </div>
              </td>

              <td class="px-4 py-2 text-gray-900 whitespace-nowrap">
                <div>
                  <div class="">
                    <div class="text-gray-500 text-sm">
                      {{ validateInfo(item.creacion) }}
                    </div>
                  </div>
                </div>
              </td>

              <td class="px-4 py-2 text-gray-900 whitespace-nowrap">
                <div>
                  <div class="">
                    <div class="text-gray-500 text-sm">
                      {{ validateInfo(item.fecha_emision) }}
                    </div>
                  </div>
                </div>
              </td>

              <td class="px-4 py-2 text-gray-900 whitespace-nowrap">
                <div>
                  <div class="mt-2">
                    <div class="text-gray-500 text-sm">
                      {{ validateInfo(item.fecha_vencimiento) }}
                    </div>
                  </div>
                </div>
              </td>

              <!-- <td class="px-4 py-2 text-gray-900 whitespace-nowrap">
                <div
                  class="px-2 py-1 border bg-white rounded-lg text-xs font-medium inline-flex items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    class="h-4 w-4 mr-1 text-gray-400"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8 7V3m8 4V3m-9 9h.01M15 12h.01M12 15h.01M15 15h.01M18 15h.01M21 10h-18c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h18c1.1 0 2 .9 2 2v2c0 1.1-.9 2-2 2zM3 10v10c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V10H3z"
                    />
                  </svg>
                  <div class="mt-1">
                    {{ diffDays(item.fecha_emision, item.fecha_vencimiento) }}
                  </div>
                </div>
              </td> -->

              <td
                class="px-4 py-2 whitespace-nowrap font-medium text-gray-900 text-xs"
              >
                <div *ngIf="validateInfo(item.vencimiento.s)">
                  <div
                    *ngIf="
                      item.vencimiento.s.toString().toLowerCase() == 'pendiente'
                    "
                  >
                    <span
                      *ngIf="
                        calculateDays(item.vencimiento.v)?.diasFaltantes > 5
                      "
                    >
                      {{ calculateDays(item.vencimiento.v)?.diasFaltantes }} d
                    </span>
                    <span
                      class="text-warning"
                      *ngIf="
                        calculateDays(item.vencimiento.v)?.diasFaltantes < 6 &&
                        calculateDays(item.vencimiento.v)?.diasFaltantes > 1
                      "
                    >
                      {{ calculateDays(item.vencimiento.v)?.diasFaltantes }} d
                    </span>
                    <span
                      class="text-danger"
                      *ngIf="
                        calculateDays(item.vencimiento.v)?.diasFaltantes < 2
                      "
                    >
                      <span
                        *ngIf="!calculateDays(item.vencimiento.v)?.fechaEstado"
                      >
                        {{ calculateDays(item.vencimiento.v)?.diasFaltantes }}
                        d</span
                      >
                      <span
                        *ngIf="calculateDays(item.vencimiento.v)?.fechaEstado"
                        >{{ calculateDays(item.vencimiento.v)?.fechaEstado }}
                      </span>
                    </span>
                  </div>
                </div>
                <div
                  *ngIf="
                    item.vencimiento.s.toString().toLowerCase() !== 'pendiente'
                  "
                >
                  <span> - </span>
                </div>
              </td>
              <td class="px-4 py-2 whitespace-nowrap">
                <span [class]="getPriorityClass(item.prioridad.name)">
                  {{ validateInfo(item.prioridad.name) }}</span
                >
              </td>
              <td class="px-4 py-2">
                <div *ngIf="item.banco_emisor">
                  {{ validateInfo(item.banco_emisor.name) }}
                </div>
                <div *ngIf="!item.banco_emisor">-</div>
              </td>

              <td class="px-4 py-2 whitespace-nowrap">
                <span
                  *ngIf="item.etapa.name && !item.etapa.code"
                  [class]="getProgressClass(item.etapa.name)"
                  >{{ validateInfo(item.etapa.name) }}</span
                >
                <span
                  *ngIf="!item.etapa.name && item.etapa.code"
                  [class]="getProgressClass(item.etapa.code)"
                  >{{ validateInfo(item.etapa.code) }}</span
                >
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="data.length == 0 && validateLoading()">
            <tr>
              <td colspan="12" class="py-4 text-center font-bold">
                No hay datos
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="isLoading && !fromInfinite">
            <tr class="border-t">
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
            </tr>
            <tr class="border-t">
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>

              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
            </tr>
            <tr class="border-t">
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
            </tr>
            <tr class="border-t">
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>

              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
            </tr>
            <tr class="border-t">
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
            </tr>
            <tr class="border-t">
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
            </tr>
            <tr class="border-t">
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>

              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
            </tr>
            <tr class="border-t">
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
              <td class="px-6 py-4">
                <div class="h-4 bg-gray-200 rounded"></div>
              </td>
            </tr>

            <!-- Agrega más filas según sea necesario -->
          </tbody>
        </table>
      </div>
      <app-paginator></app-paginator>
    </div>
  </div>
</div>
