import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as alertifyjs from 'alertifyjs';
import Swal from 'sweetalert2';
import { DirectDebitService } from '../../services/direct-debit/direct-debit.service';
import { MassiveService } from '../../services/massive.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'app-dashboard-massive-payments',
  templateUrl: './dashboard-massive-payments.component.html',
  styleUrls: ['./dashboard-massive-payments.component.css'],
  providers: [DatePipe]

})
export class DashboardMassivePaymentsComponent implements OnInit {
  downloading = false;

  startTraceDocument = true;
  startTraceDocumentDomiciliation = true;
  resumen: any;
  file: any;
  file2: any;
  loading = true;
  sessionUser: any;
  profiles: any;
  profile: any;
  current_direct_debit: any;
  status_sections: any = [];
  current_status_sections: any = [];
  loading_section: any = [];
  show_conciliacion_button = false;

  obj_conciliacion: any;
  obj_conciliacion_file_error: any;

  provider: any = {
    tax_id_letter: 'J',
    tax_id: '311501878',
    tax_id_verification: '0',
  };
  template: string = '/lotes/bdv/send_final';
  sections: any = [
    {
      id: 1,
      name: 'Generar Pagos',
      status: 'INITIAL',
    },
    {
      id: 2,
      name: 'Descargar TXT',
      status: 'GENERATED',
    },
    {
      id: 3,
      name: 'Descargado',
      status: 'DOWNLOADED',
    },
    {
      id: 4,
      name: 'Pagos cargados al banco',
      status: 'UPLOADED',
    },
    {
      id: 5,
      name: 'Procesar Respuesta del banco',
      status: 'PROCESING',
    },
    {
      id: 6,
      name: 'Finalizado',
      status: 'FINISHED',
    },
  ];

 

  banks: any = [];

  pending: any;

  view = 1;
  form: FormGroup | any = this.formBuilder.group({
    bank: [''],
    suscriptor: [''],
    account: [''],
    type: ['2']
  });
  files: any = [];

  date: any = new Date();
  current_date: any = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

  btn_restart = false;
  btn_restart_conciliation = false;
  response_trace_conciliation: any;

  config: any; 
  config_read: any;

  bank: any;

  changesDirectDebitSubscription: any;
  type: string = "MASSIVE_PAYMENTS";

  accounts: any = [];
  accounts_list: any = [];
  accounts_suscriptor: any = [];

  constructor(
    private userService: UserService,
    private offcanvasService: NgbOffcanvas,
    private formBuilder: FormBuilder,
    private directDebitService: DirectDebitService,
    private massiveService: MassiveService,
    public _auth: AuthService,
    private datePipe: DatePipe,
    public socketService: SocketioService,
    private commonService: CommonService,
    private _user: UserService,

    
  ) {}

  async ngOnInit() {
    this.sessionUser = this._auth.sessionUser;
    this.profiles = this._auth.getProfiles();
    this.profile = this.getProfile(this.sessionUser);
    this.banks = await this.getBanks();
    this.banks = this.banks.res;
    this.setDefault();
    this.updateCurrentDirectDebit();
    this.config = await this.getDirectDebitConfig('template');
    this.config_read = await this.getDirectDebitConfig('read');
    this.getAccounts('SUSCRIPTOR');
    // sockets
    this.changesDirectDebitSubscription = this.socketService.changesDirectDebit.subscribe((resp: any) => {
      if(resp){
        console.log('Massive Payment: Cambió');
        console.log(resp.trace);
        if(resp.trace === this.current_direct_debit?.trace) {
          console.log('refrescaré');
          this.updateCurrentDirectDebit();
        }
      }
    });


  }

  getDirectDebitConfig = (type: string): Promise<any> => {
    let bank = this.form.getRawValue().bank;
    return this.directDebitService.getDirectDebitConfig(bank, type, 'DOMICILIATION');
  };

  getCurrentDate = async () => {
    this.pending = await this.directDebitService.pendingLote();
    if (this.pending.resp.length > 0) {
      this.date = this.datePipe.transform(
        this.pending.resp[0].date,
        'yyyy-MM-dd'
      );
    } else {
      this.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    }
  };

  getProfile(user: any) {
    let profiles = user?.profile;
    let profile;
    if (profiles) {
      let value = this.profiles[profiles[0]];
      let key = profiles[0];
      profile = { key, value };
    }
    return profile;
  }

  updateStatusSections = async () => {
    await this.getStatusCurrentDirectDebit();
    let iter_section;
    this.sections.map((section: any) => {
      this.status_sections[section.status] = 'PENDING';
      iter_section = this.validateSection(section.status);
      if (iter_section) {
        this.status_sections[section.status] = iter_section;
      }
    });
    this.config = await this.getDirectDebitConfig('template');
    this.config_read = await this.getDirectDebitConfig('read');
    this.loading = false;
  };

  validateSection = (status: string) => {
    return this.current_status_sections.find((section: any) => {
      return section.status === status;
    });
  };



  // updateCurrentDirectDebit = async (direct_debit: any) => {
  //   this.getCurrentDate();
  //   //this.current_direct_debit = null;
  //   this.files = [];
  //   if(direct_debit) {
  //     this.current_direct_debit = direct_debit;

  //   } else {
  //     this.current_direct_debit = null;
  //   }
  //   this.updateStatusSections();
  // };

  updateCurrentDirectDebit = async () => {
    this.getCurrentDate();
    //this.current_direct_debit = null;

    
    this.files = [];
    this.current_direct_debit = await this.getMassivePaymentsByBankAndType(
      this.form.getRawValue().bank,
      this.type,
      this.date,
      this.form.getRawValue().suscriptor,
      this.form.getRawValue().account
    );
    this.btn_restart = false;
    if (this.current_direct_debit) {
      if (this.current_direct_debit?.res?.length > 0) {
        this.current_direct_debit = this.current_direct_debit?.res[0];
        this.getCurrentBank(this.current_direct_debit?.bank)
      } else {
        this.current_direct_debit = null;
        this.bank = null;
      }
    } else {
      this.current_direct_debit = null;
      this.bank = null;
    }
    this.updateStatusSections();
    this.startTraceDocument =false;
    setTimeout(()=> { this.startTraceDocument = true }, 5);

    this.startTraceDocumentDomiciliation =false;
    setTimeout(()=> { this.startTraceDocumentDomiciliation = true }, 5);

  }

  getCurrentBank = (bank: any) => {
    this.bank = this.banks.find((ele: any)=> bank===ele._id);
  }

  setResponse = (elements: any[], type: string) => {
    console.log('Esta es la respuesta');
    console.log(elements);
    if(elements.length>0) {
      if(elements[0].state==="ERROR" && type==="START") {
        this.btn_restart = true;
      }else if(elements[0].state==="ERROR" && type==="CONCILIATION") {
        this.btn_restart_conciliation = true;
        this.response_trace_conciliation = elements;
      } else if(type==="CONCILIATION") {
        this.response_trace_conciliation = elements;
      }
    }
  }


  getStatusCurrentDirectDebit = async () => {
    if (this.current_direct_debit) {
      this.current_status_sections = await this.getMassivePaymentsStatuses(
        this.current_direct_debit._id
      );
      this.current_status_sections = this.current_status_sections.res;
    } else {
      this.current_status_sections = [];
    }
  };

  setDefault = () => {
    if (this.banks.length >= 1) {
      this.form.get('bank').setValue(this.banks[0].code);
      this.form.get('bank').patchValue(this.banks[0].code);
    }
  };

  getMassivePaymentsByBankAndType = (
    bank: string,
    type: string,
    date: any,
    customer_suscriptor: any,
    payment_method_suscriptor: any

  ): Promise<any> => {
    return this.massiveService.getMassiveProcess(bank, type, date, customer_suscriptor, payment_method_suscriptor);
  };


  getMassivePaymentsStatuses = (massive_payments_id: string): Promise<any> => {
    return this.massiveService.getMassiveProcessStatuses(massive_payments_id);
  };

  getBanks = async () => {
    return await this.userService.getBanksWithMassivePaymentsEnabled({});
  };

  openModalBanks = (content: TemplateRef<any>, data: any = {}) => {
    try {
      this.offcanvasService.open(content, {
        position: 'bottom',
        keyboard: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  pendingDirectDebit = async () => {
    return await this.directDebitService.pendingLote();
  };

  

  startProcess = async () => {
      this.loading_section['INITIAL'] = true;
      let body = {
        type: this.type,
        bankCode: this.form.getRawValue().bank,
        user: this.sessionUser.uid,
        template: this.config.template,
        provider: this.provider,
        customer_suscriptor: this.form.getRawValue().suscriptor,
        payment_method_suscriptor: this.form.getRawValue().account,
        type_process: this.form.getRawValue().type,
        config: this.config ?? null,
      };
      try {
      debugger;
        // este es el único servicio que genera todo el proceso de domiciliación, en file llega el url del archivo generado
        let response = await this.directDebitService.startMassivePaymentProcess(body);
        
        
        if (response.success) {
          if (response.data) {
            await this.updateCurrentDirectDebit();
            delete this.loading_section['INITIAL'];
          } else {
            alertifyjs.error(
              'No hay pagos pendientes que cumplan con los críterios seleccionados'
            );
            delete this.loading_section['INITIAL'];
          }
        } else {
          alertifyjs.error(
            response.description
          );
          delete this.loading_section['INITIAL'];
        }
      } catch(error) {
          console.log(error);
          delete this.loading_section['INITIAL'];
          alertifyjs.error(
                error
          );
      }
    
  };

  downloadCheck = async () => {
    if(this.status_sections['FINISHED']==='PENDING' && this.status_sections['PROCESING']==='PENDING' && this.status_sections['UPLOADED']==='PENDING'){
      let body = {
        massive_process_id: this.current_direct_debit._id,
        status: 'DOWNLOADED',
        user: this.sessionUser.uid,
      };
      console.log(body);
      await this.massiveService.saveMassivePaymentsStatus(body);
      await this.updateCurrentDirectDebit();
    }
  };

  offcanvasServiceClose() {
    this.view = 1;
    this.offcanvasService.dismiss();
  }

  reset = (evt: any, type: any) => {
    this.show_conciliacion_button = false;
    if(type==='success') {
      this.obj_conciliacion = null;
    }
    if(type==='error') {
      this.obj_conciliacion_file_error = null;
    }
  };

  getResponse = (response: any, type: any) => {
    console.log(response);
    console.log(type);
    if(type==='' || type==='success') {
      if (response) {
        this.resumen = response;
        this.obj_conciliacion = response.response;
        this.file = response.file;
      } else {
        this.resumen = null;
        this.obj_conciliacion = null;
        this.file = null;
      }
    } else if(type==='error') {
      if (response) {
        this.resumen = response;
        this.obj_conciliacion_file_error = response.response;
        this.file2 = response.file;
      } else {
        this.resumen = null;
        this.obj_conciliacion_file_error = null;
        this.file2 = null;
      }
    }
debugger;
    if(this.obj_conciliacion) {
      this.show_conciliacion_button = true;
    } else {
      this.show_conciliacion_button = false;
    }
  };

  conciliar = async () => {
    let redflags = this.resumen?.resumen?.redflags.length > 0 ? true : false;
    if(redflags) {
      Swal.fire({
        title: 'Importante',
        text: 'Por favor, revise detalladamente la respuesta del banco. Se han encontrado elementos que pueden generar problemas en la conciliación. ¿Ya lo ha revisado? ¿Desea continuar?',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        showCancelButton: true,
        confirmButtonText: `Si!`,
        cancelButtonText: `Cancelar!`,
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.ejecutarConciliacion();
        }
      });
    } else {
      this.ejecutarConciliacion();
    }


    
    
  };

  ejecutarConciliacion = async () => {
    if (this.current_direct_debit && !this.loading_section['PROCESING']) {
        // este el el loading del botón
        this.loading_section['PROCESING'] = true;

        // lectura del header de la respuesta
        let header = this.getHeader(this.obj_conciliacion.data, this.bank.acron);
        debugger;
        // objeto requerido por el servicio de conciliación
        let obj = {
          bank_acron: this.bank.acron,
          config: this.config_read.config,
          bank: this.current_direct_debit.bank,
          tax_id: this.provider.tax_id_letter + this.provider.tax_id,
          massive_process_id: this.current_direct_debit._id,
          token: localStorage.getItem('x-token'),
          rate: this.current_direct_debit.rate?this.current_direct_debit.rate: undefined,
          user: this.sessionUser.uid,
          url: this.obj_conciliacion.file.url,
          customer_suscriptor:  this.form.getRawValue().suscriptor,
          account_suscriptor:  this.form.getRawValue().account,
          url_error: this.obj_conciliacion_file_error?.file.url ?? null,
          trace: this.current_direct_debit.trace
        };
        try {
          try{
            // este es el único servicio encargado de la conciliación Juan
            let response = await this.massiveService.conciliar_payments(JSON.stringify(obj), this.file, this.file2);
            if(response) {
              if(!response.success) {
                alertifyjs.warning(
                  response.description
                );
              } 
            }
            
            // estos servicios se utilizan para actualizar los estatus de las vistas, segun la tabla de direct debit statuses
            setTimeout(async ()=> {
              await this.updateCurrentDirectDebit();
              // se desmarca el proceso de conciliación activo
              this.show_conciliacion_button = false;
              this.loading_section['PROCESING'] = false;
            }, 0);
            

            
            
          } catch(e) {
            alertifyjs.error(
              'Ha ocurrido un error en el proceso de conciliación, contacte con el administrador o reintente conciliar.' + e
            );
            this.show_conciliacion_button = true;
            this.loading_section['PROCESING'] = false;
          }
        } catch (error) {
          console.log(error);
          this.show_conciliacion_button = true;
          this.loading_section['PROCESING'] = false;
          alertifyjs.error(
            'Ha ocurrido un error en el proceso de conciliación, contacte con el administrador o reintente conciliar.' + error
          );
        }
    }
  }

 
  formatDataToExcel = (obj: any) => {
    let response: any = [];

    obj.map((element: any) => {
      console.log(element);
      response = [
        ...response,
        {
          CEDULA: element.identity,
          CREDITO: element.num_credit,
          REFERENCIA: element.reference,
          'NUMERO DE CUENTA': element.num_cuenta,
          'PROCESADO POR EL BANCO': element.success ? 'SI' : 'NO',
          DESCRIPCION: element.description,
          CONCILIADO: element?.conciliation?.reconciled_payment ? 'SI' : 'NO',
          MONTO: element.conciliation
            ? this.toFixedApp(
                element.conciliation.amount * element.conciliation.rate
              )
            : 0,
        },
      ];
    });
    return response;
  };

  toFixedApp = (x: any) => {
    let a: any = `e+${2}`;
    let b: any = `e-${2}`;
    return +(Math.round(x + a) + b);
  };

  findConciliationObject = (obj: any, reference: any) => {
    return obj.find((element: any) => element.paymentMethodId === reference);
  };

  getElements = (obj: any, type: any) => {
    let response: any = [];
    obj
      .filter((row: any) => row.TIPOREG !== '01')
      .map((row: any) => {
        if (row.TIPOREG === '02') response = [...response, row];
        if (row.TIPOREG === '03') {
          let position = response.findIndex((ele: any) => {
            return ele.REFDEB === row.REFDEB && !ele.ERROR;
          });
          if (position != -1) {
            console.log(response[position]['TOTCOB']);
            if(response[position]['TOTCOB']==="0.00"){
              response[position]['ERROR'] = row;
            }
          }
        }
      });
    return response;
  };

  confirm_upload = async () => {
    let manual_lote;
    if(this.bank.direct_debit_manual_lote) {
      Swal.fire({
        title: "Ingrese el número de identificación del lote:",
        input: "text",
        inputAttributes: {
          autocapitalize: "off"
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        showCancelButton: true,
        confirmButtonText: `Continuar!`,
        cancelButtonText: `Cancelar!`,
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          if(result.value!=="") {
            this.process_confirm(result.value);
          } else {
            Swal.fire({
              icon: "warning",
              html: 'El número de lote es obligatorio.'
            });
          }
          
        }
      });
    } else {
      this.process_confirm(null);
    }
    
  };

  process_confirm = (lote: any) => {
    Swal.fire({
      title: 'Confirmación',
      text: 'Solo debe confirmar cuando el archivo se haya recibido de forma exitosa por el banco ¿Desea confirmar?',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: `Si!`,
      cancelButtonText: `Cancelar!`,
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.loading_section['UPLOADED'] = true;
        let body_2 = {
          massive_process_id: this.current_direct_debit._id,
          status: 'UPLOADED',
          user: this.sessionUser.uid,
          url: undefined,
        };
        await this.massiveService.saveMassivePaymentsStatus(body_2);

        if(lote) {
          await this.massiveService.massive_manual_lote({
            id: this.current_direct_debit._id,
            manual_lote: lote
          });
        }
        
        this.loading_section['UPLOADED'] = false;
        await this.updateCurrentDirectDebit();
      }
    });
  }

  cancel_process = () => {
    Swal.fire({
      title: 'Alerta',
      text: '¿Desea cancelar el proceso, esta acción no se puede revertir, desea continuar?',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: `Si!`,
      cancelButtonText: `Cancelar!`,
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.loading_section['UPLOADED'] = true;
        let body = {
          id: this.current_direct_debit._id,
          user: this.sessionUser.uid,
        };
        await this.massiveService.cancel(body);
        this.loading_section['UPLOADED'] = false;
        await this.updateCurrentDirectDebit();
      }
    });
  };

  restart_process_conciliation = async() => {
    let body = {
      trace: "MASSIVE_PAYMENTS_CONCILIATION_"+this.current_direct_debit.trace
    };
    await this.directDebitService.cancel_direct_debit_trace(body);
    this.btn_restart_conciliation = false;
    this.response_trace_conciliation = null;
    await this.updateCurrentDirectDebit();
  };

  restart_process = async() => {
    this.btn_restart = false;
    this.loading_section['UPLOADED'] = true;
    let body = {
      id: this.current_direct_debit._id,
      user: this.sessionUser.uid,
    };
    await this.massiveService.cancel(body);
    this.loading_section['UPLOADED'] = false;
    await this.updateCurrentDirectDebit();
  };


  getHeader = (obj: any, type: any) => {
    let response: any = [];
    if(type==="Mercantil" || type==="Bancaribe") {
      return obj.filter((row: any) => row.TIPOREG.toString() === '1');
    } else {
      return obj.filter((row: any) => row.TIPOREG === '01');
    }
  };

  formatElements = (elements: any) => {
    let response: any = [];
    let element: any;
    elements.map((ele: any) => {
      element = {
        res: ele.TIPOREG,
        success: ele.ERROR ? false : true,
        identity: ele.CEDULA,
        num_cuenta: ele.NROCTA1,
        reference: ele.REFDEB,
        amount: ele.TOTCOB,
        description: ele.DESCSTA,
        num_credit: ele.REFDEB.replace(/^0+/, '').slice(0, -1),
        error: ele.ERROR ? ele.ERROR : undefined,
      };
      response = [...response, element];
    });
    return response;
  };

  downloadFile = async (url:any, extension: string, name: string) => {
    let res = await this.directDebitService.downloadImage(url, extension);
    this.downloadCheck();
    if(res) {
      saveAs(res, `${name}.${extension}`);
    }
  }

  finalizar = async() => {
    let body = {
      id: this.current_direct_debit._id
    };
    await this.massiveService.finish(body);
    this.updateCurrentDirectDebit();
  }


  getAccounts = async (from: any) => {
    let account = await this._user.getPersonPeople_type({type: from});
    this.accounts =  account?.resp?.rows;
    this.accounts =   this.accounts.map((item: any) => {                     
      this.accounts_list.push(
        {
          value:  item?.person?.code , text:`${ item?.person?.name }  ${ item?.person?.lastName? item?.person?.lastName:''} ${ item?.identitys[0]?.code_identity?.code }-${ item?.identitys[0]?.identity }`});
          return {
               code : item?.person?.code,
               name: `${ item?.person?.name } ${ item?.person?.lastName? item?.person?.lastName:''}  ${ item?.identitys[0]?.code_identity?.code }-${ item?.identitys[0]?.identity }`,
               identity:  item?.identitys[0]
          } 
    });


    // genero el accounts_list
  }

  changeBank() {
    this.getAccountsSuscriptor();
  }


  getAccountsSuscriptor = async () => {
       let accounts_suscriptor = await this.commonService.getPayment_method({
        code_person: this.form.getRawValue().suscriptor
       });
       console.log(this.bank);
       debugger;
       if(accounts_suscriptor?.resp){
          this.accounts_suscriptor = accounts_suscriptor?.resp.filter((elem:any)=>elem.bank.code.toString() === this.form.getRawValue().bank.toString());
          if(this.accounts_suscriptor.length>0) {
              this.form.get('account').setValue(this.accounts_suscriptor[0]._id);
              this.form.get('account').patchValue(this.accounts_suscriptor[0]._id);
          } else {
            this.form.get('account').setValue('');
            this.form.get('account').patchValue('');
          }
       } else {
          this.form.get('account').setValue('');
          this.form.get('account').patchValue('');
       }
       this.updateCurrentDirectDebit();
   }



  ngOnDestroy() {
    if(this.changesDirectDebitSubscription) {
      this.changesDirectDebitSubscription.unsubscribe();
    }
  }

  generar_reporte = async  (massive_payment_id: any) => {
    this.downloading = true;
    let response:any = await this.massiveService.generate_report_massive_payments({
      massive_payment_id,
      payment_method_suscriptor: this.current_direct_debit.payment_method_suscriptor
    });
    this.downloadFile(response?.file?.url, 'xlsx', 'resumen_lote_'+this.current_direct_debit?.lote);
    this.downloading = false;
  }

}
