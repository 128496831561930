import { Component, Input, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-filter-details',
  templateUrl: './filter-details.component.html',
  styleUrls: ['./filter-details.component.css'],
})
export class FilterDetailsComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() type: string = '';
  list: string[] = [];
  auxList: any = [];
  filterText: string = '';
  constructor(public _user: UserService) {}

  ngOnInit(): void {
    this.setDataType();
  }

  setDataType() {

    debugger
    if (this.type == 'bank') {
      this.data.forEach((bank: any) => {
        this.list.push(bank.name);
      });
    } else if (this.type == 'step') {
       /**
         * Filtro de etapa revisar finalizado, Conciliación y quitar documentación reunion: 03/12
         */


      const found = this.data.find(
        (element) => element.name.toString().toLowerCase() == 'finalizado'
      );

      if (!found) {
        this.data.push({name: "Finalizado", code: "Finalizado" })
      }
      this.data.forEach((step: any) => {
        if(!step.name.toString().toLowerCase().includes('archivo')) {
          this.list.push(step.name);
        }

      });
    } else if (this.type == 'currency' || this.type == 'status' || this.type == 'order' || this.type == 'signature') {
      this.list = this.data;
    } else if (this.type == 'transmitterList' || this.type == 'receiverList') {
      this.data.forEach((item: any) => {
        this.list.push(item.text);
      });
    }
  }

  filteredItems() {
    if (this.filterText) {
      this.auxList = this.list.filter((item) =>
        item.toLowerCase().includes(this.filterText.toLowerCase())
      );
    } else {
      this.auxList = this.list;
    }
    console.log(this.auxList.length);
  }

  selectItem(item: string) {
    console.log(item);
    if (this.type == 'currency' || this.type == 'status' || this.type == 'order' || this.type == 'signature') {
      this._user.onFilterItemSelected.emit({type: this.type, item: item});
    }

    if (this.type == 'step') {
      this.data.forEach((step: any) => {
        if (step.name == item) {
          this._user.onFilterItemSelected.emit({type: this.type, item: step});
        }
      });
    }

    if (this.type == 'bank') {
      this.data.forEach((bank: any) => {
        if (bank.name == item) {
          this._user.onFilterItemSelected.emit({type: this.type, item: bank});
        }
      });
    }
    if (this.type == 'transmitterList' || this.type == 'receiverList') {
      this.data.forEach((itemList: any) => {
        if (itemList.text == item) {
          this._user.onFilterItemSelected.emit({type: this.type, item: itemList});
        }
      });
    }
  }

}

const EXAMPLE_LIST = [
  'Por definir',
  'Por definir',
  'Por definir',
  'Por definir',
];
