<div class="mt-md-3 container-fluid">

    <div class="row mt-2">
        <div class=" col-12 col-md-8 offset-md-2">
            <div class="row rmt-2">
                <div class="col-12  col-md-8 offset-md-2">
                    <h5 class=" text-secondary text-center mb-5">
                        Reportes
                    </h5>


                    <div class="row">
                        <div class="mb-4 mt-1 col-12 col-md-12 ">

                            <div class="form-floating ">

                                <select (change)="setNewType()" [(ngModel)]="type" class="form-select " id="floatingSelect " aria-label="Floating label select example ">                            
                                  <option   value="1">Compras financiadas y Pagos</option> -->
                                  <option   value="2">Reporte de Cuotas</option>
                                  <option   value="3">Reporte de Cartera de Credito</option>
                                  
                                  <option   value="4">Reporte de Pagos</option>
                                  <!-- <option   value="5">Reporte de Último Pago de Cada Credito</option> -->
                                  <option   value="6">Reporte de Cartera de Clientes</option>
                                  <!--  <option   value="7">Reporte de Clientes Domiciliados </option> -->
                                  <option value="15">Reporte de Distribución de Pagos</option>


                                </select>

                                <label for="floatingSelect ">Tipo de Reporte</label>

                            </div>

                        </div>

                        <div *ngIf="type == 0" class=" col-12 col-md-6 ">
                            <span class="d-block d-md-none">
                                
                            <mbsc-date (onSet)="dateSet($event)" [(ngModel)]="date_base" [options]="mobileSettings" placeholder="dd/mm/aaaa"></mbsc-date>
                        </span>
                            <span class="d-none d-md-block ">
                            <mbsc-date (onSet)="dateSet($event)" [(ngModel)]="date_base" [options]="desktopSettings" placeholder="dd/mm/aaaa"></mbsc-date>
                        </span>
                        </div>

                        <div class="mb-4 mt-2 col-12 col-md-6 " *ngIf="type == 2" >
                            <div class="form-floating ">

                            <select [(ngModel)]="typeInstallMents" class="form-select " id="floatingSelect " aria-label="Floating label select example ">
                                <option   value="">Todas las cuotas</option>
                                <option   value="p">Cuotas pendientes</option>
                            </select>
                                <label for="floatingSelect ">Estatus</label>
                            </div>

                        </div>

                        <div *ngIf="type == 1 || type == 2   || type == 3  || type == 6  || type == 7 " class=" col-12 col-md-6 ">
                            <div class="row w-100">

                                <div class="mt-1 col-12 form-group ">
                                    <input style="
                                        padding: 16px 9px;
                                        background: white;
                                    " id="demo-external" (onSet)="birthdaySet()" [(ngModel)]="range" mbsc-range class="form-control" [mbsc-options]="dateSetting" #externalRange="mobiscroll" />
                                </div>
                                <!-- <div class="mt-2 col-1">
                                    <i class="fa-icon-filter far fa-calendar me-2" (click)="externalRange.instance.show()"></i>
                                </div> -->
                            </div>
                        </div>

                        <div *ngIf="type == 7" class="mt-1 col-12 col-md-6 ">

                            <div class="form-floating ">

                                <select [(ngModel)]="status_approved" class="form-select " id="floatingSelect " aria-label="Floating label select example ">                            
                                  <!-- <option   value="1">Compras financiadas y Pagos</option> -->
                                  <option   value="ALL">All</option>
                                  <option   value="APPROVED">Aprobados</option>
                                  <option   value="REFUSED">Rechazados</option>
                                  <option   value="WAIT_PSICOMETRICO">Pendiente por psicometrico</option>

                                
                                </select>

                                <label for="floatingSelect ">Estatus de Aprobación</label>
                            </div>

                        </div>

                    </div>

                    <div class="row mt-4">
                        <div class="col-12 mt-2 ">
                            <div class="d-grid gap-2 ">
                                <button type="button " class="btn btn-primary  " (click)="generate()">
                                        <span *ngIf="!loadingXls">Exportar </span> 
                                        <span *ngIf="loadingXls">Generando...</span> 
                                    </button>
                            </div>
                        </div>
                    </div>

                    <div class="pt-4" *ngIf="trace_documets?.length > 0">

                        <div class="text-end mt-2">
                            <i (click)="setNewType()" class="fas fa-sync-alt"></i>
                        </div>
                        <div class=" d-flex text-dark  " *ngFor="let item of trace_documets">
                            <div class="card w-100 mt-3">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between">

                                        <div class="text-capitalize fw-bold ">
                                            <span *ngIf="item?.state == 'COMPLETE'" class="text-success">Completado</span>
                                            <span *ngIf="item?.state == 'PENDING'" class="text-warning">Pendiente</span>
                                            <span *ngIf="item?.state == 'ERROR'" class="text-danger">Error al generar</span>

                                        </div>

                                        <div class="ms-auto">
                                            <div *ngIf="item?.url" (click)="dowloader_document(item?.url)" class="text-primary cursor-pounter">Descargar</div>

                                            <div>

                                            </div>
                                            <div *ngIf="!item?.url" class="small text-secondary cursor-pounter ">Tiempo aproximado <b>{{item?.time_aprox}}Min</b></div>

                                        </div>

                                    </div>
                                    <div class="d-flex justify-content-between text-dark mt-3 small">
                                        <div class="">
                                            <div class=" text-capitalize pt-1 fw-bold">Iniciado</div>
                                        </div>
                                        <div class="ms-auto">

                                            <div class="">{{item?.date_start | date: 'dd/MM/yyyy h:mm:ss a'}}</div>

                                        </div>
                                    </div>

                                    <div *ngIf="item?.date_end" class="d-flex justify-content-between text-dark mt-1 small">
                                        <div class="">
                                            <div class=" text-capitalize pt-1 fw-bold">Finalizado</div>
                                        </div>
                                        <div class="ms-auto">
                                            <div class="">{{item?.date_end | date: 'dd/MM/yyyy h:mm:ss a'}}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>

                        <!-- <pre>{{ trace_documets | json }}</pre> -->

                    </div>

                </div>



            </div>



        </div>
    </div>

</div>